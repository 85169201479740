.publish {
  position: relative;
}

.ant-upload-list {

  .ant-upload-list-picture-card-container,
  .ant-upload-select {
    width: 146px;
    height: 146px;
  }
}

.publish-quill {
  .ql-editor {
    min-height: 300px;
  }
}